<template lang="pug">
.collection-previews(v-if="templates.length")
  .d-flex.justify-content-between.mb-5(
    :class="{ 'align-items-start': subtitle, 'align-items-end': !subtitle }"
  )
    .collection-previews-heading.collection-previews-heading--title(:class="{ 'mb-3': subtitle }")
      editable-title(
        h3
        v-if="isTitleEditable"
        :title="name"
        :maxLength="50"
        cancel-on-blur
        @renameTitle="renameTitle($event)"
      )
      om-heading(:h3="!smallTitle" :h4="smallTitle" :h5="smallerTitle" v-else) {{ name }}
      om-body-text.collection-previews-heading--subtitle.mb-4(
        v-if="subtitle"
        bt400md
        v-html="subtitle"
      )
    .collection-previews-heading
      .collection-previews-heading-action(v-if="isArchiveEnabled")
        om-button(ghost small icon="archive" @click="archiveCollection") {{ $t('archive') }}
      slot(name="see-all")
        om-link.text-nowrap(
          v-if="showLink || forcedShowLink"
          primary
          withIconRight
          @click="onSeeAll"
        ) {{ $t('seeAll') }}

  .row
    .col-12.col-lg-4(v-for="template in templateList")
      TemplateBox(
        :title="showTitle ? getTitle(template.useCase) : null"
        :key="templateKey(template)"
        @observable="addObservable($event.$el)"
        heap-event="new-selector-campaign-collection"
        :template="template"
        :themeKit="themeKit"
        :baseThemeKitName="baseThemeKitName"
      )
</template>

<script>
  import { nanoid } from 'nanoid';
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import observableCollectionMixin from '@/mixins/observableCollection';
  import EditableTitle from '@/components/EditableTitle.vue';
  import templateBoxParent from '../mixins/templateBoxParent';

  export default {
    components: {
      EditableTitle,
      TemplateBox: () => import('@/components/TemplateChooser/components/TemplateBox.vue'),
    },
    mixins: [observableCollectionMixin, templateBoxParent],
    props: {
      name: { type: String, required: true },
      to: { type: [String, Object] },
      isTitleEditable: { type: Boolean, default: () => false },
      isArchiveEnabled: { type: Boolean, default: () => false },
      templates: { type: Array, default: () => [] },
      themeKit: { type: Object, default: () => null },
      useCaseMap: { type: Object, default: () => ({}) },
      baseThemeKitName: { type: String, default: null },
      smallTitle: { type: Boolean, default: false },
      smallerTitle: { type: Boolean, default: false },
      showTitle: { type: Boolean, default: true },
      subtitle: { type: String, default: null },
      forcedShowLink: { type: Boolean, default: false },
    },
    data: () => ({ uuid: nanoid(12), templateList: null }),
    computed: {
      ...mapGetters('templateChooser', ['displayedTemplates']),
      showLink() {
        return this.to && this.templates.length > 3;
      },
      isHome() {
        return this.$route.name === 'templates';
      },
    },
    mounted() {
      this.setupTemplateList();
    },
    methods: {
      ...mapMutations('templateChooser', ['ADD_DISPLAYED_TEMPLATE']),
      ...mapActions('templateChooser', ['resetFilters']),
      setupTemplateList() {
        if (!this.isHome) {
          this.templateList = (this.templates || [])?.slice?.(0, 3) ?? [];
          return;
        }
        if (this.templateList) return;

        const couldBeShown = this.templates.filter((template) => {
          const notShownElsewhere = !this.displayedTemplates.includes(template._id);
          if (!notShownElsewhere) {
            this.ADD_DISPLAYED_TEMPLATE(template._id);
          }
          return notShownElsewhere;
        });

        if (couldBeShown.length < 3) {
          for (const template of this.templates) {
            if (!couldBeShown.some((t) => t._id === template._id)) {
              couldBeShown.push(template);
            }
            if (couldBeShown.length === 3) break;
          }
        }

        couldBeShown.forEach((template) => this.ADD_DISPLAYED_TEMPLATE(template._id));
        this.templateList = couldBeShown.slice(0, 3);
      },
      renameTitle(title) {
        this.$nextTick(() => {
          this.$emit('renameCollection', title);
        });
      },
      archiveCollection() {
        this.$emit('archive');
      },
      templateKey(template) {
        if (this.themeKit?.id) {
          return `${this.uuid}-${this.themeKit?.id}-${template._id}`;
        }
        return `${this.uuid}-${template._id}`;
      },
      async onSeeAll() {
        this.$emit('track', { setting: 'theme_open', options: this.name });
        this.$router.push(this.to);
      },
      getTitle(useCaseId) {
        return this.useCaseMap?.[useCaseId]?.name?.[this.$i18n.locale];
      },
    },
  };
</script>
<style lang="sass">
  @import "@/sass/variables/_colors.sass"
  .collection-previews-heading
    display: flex
    column-gap: 1rem
    align-items: center
    height: 2rem

    &--title
      display: flex
      flex-direction: column
      align-items: flex-start
      gap: .25rem

    &--subtitle
      color: $om-gray-600 !important

    &-action
      min-width: auto
      display: none
</style>
